<template>
  <fragment v-if="!isEmbedView">
    <!-- desktop version -->
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="header-container grey">
      <AppHeaderLayoutDesktop
        :user-id="userId"
        :user-info="userInfo" />
    </div>
    <!-- </v-app-bar> -->

    <!-- mobile version -->
    <TopHeaderSignUp v-else />
  </fragment>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppHeader',
  components: {
    AppHeaderLayoutDesktop: () => import('@/components/App/AppHeader/AppHeaderLayout/AppHeaderLayoutDesktop'),
    TopHeaderSignUp: () => import('@/components/App/AppTopHeaderSignUp'),
  },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['userId']),
    isEmbedView() {
      return ['embed-collection'].includes(this.$route.name);
    },
  },
};
</script>
<style>
.header-container {
  position: sticky;
  top: 0;
  z-index: 9;
}
</style>